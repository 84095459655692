import React from 'react';
import { useEffect } from 'react';
import { useStaticQuery, graphql } from 'gatsby';
import Prism from 'prismjs';
import SgLayout from '~layouts/sg-layout';
import CodeBlock from '~2-components/SgCodeBlock/SgCodeBlock';
import CheckList from '~2-components/CheckList/CheckList';
import CheckListKontent from '~3-data-components/CheckListKontent';

const checkListEg = `import CheckList from '~2-components/CheckList/CheckList';

<CheckList>
    <ul>
        <li>etc.</li>
        <li>etc.</li>
    </ul>
</CheckList>
`;

const SgCheckList = () => {
    useEffect(() => {
        // call the highlightAll() function to style our code blocks
        Prism.highlightAll();
    });

    const allChecklists = useStaticQuery(
        graphql`
            query SGAllChecklists {
                allKontentItemChecklist {
                    nodes {
                        system {
                            id
                        }
                    }
                }
            }
    `);

    const allChecklistContainers = allChecklists.allKontentItemChecklist.nodes;

    return (
        <SgLayout>
            <section className='sg-content'>
                <div className='container'>
                    <h1>CheckList</h1>
                    <h3>How to use</h3>
                    <CodeBlock
                        code={checkListEg}
                    />

                    <h3>Example</h3>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    <CheckList>
                        <ul>
                            <li>Aggravation of a physical or mental injury</li>
                            <li>Physical injury, disability or death caused in the workplace or ‘over the course of employment’.</li>
                            <li>Psychological condition due to workplace stress or bullying.</li>
                            <li>Loss of hearing from noise exposure in the workplace</li>
                            <li>Disease diagnosis after exposure in the workplace to hazardous materials</li>
                        </ul>
                    </CheckList>
                </div>
            </section>
            <section className="sg-content">
                <div className="container">
                    <h2>All LIVE Kontent checklists</h2>
                </div>
            </section>
            <section className="project-example">
                <div className="container">
                    {allChecklistContainers.map((item, i) => {
                        return <CheckListKontent key={i} {...item}/>;
                    })}
                </div>
            </section>
        </SgLayout>
    );
};

export default SgCheckList;
